/*========== Widgets scss =========*/
.widget-heading {
	font-size: 1.625rem; //26px;
	font-weight: 500;
	color: $gray-600;
	margin-bottom: 1.875rem; //30px;
}
//To Do List widget
.to-do-list {
  ul {
    > div {
      &:hover {
        .hover-action {
          opacity: 1;
        }
      }
    }
  }
}
//New Mail widget
.hover-action {
  opacity: 0;
  transition: all 0.4s linear 0s;
  button, a {
    height: 34px;
    width: 34px;
    font-size: 1.3rem;
  }
}
.new-mail {
  > div, > li {
    padding: 0.625rem 0.9375rem; //10px 15px;
    @include border(1px solid, $input-border-color, bottom);
    &:hover {
      .hover-action {
        opacity: 1;
      }
    }
  }
  .subject {
    font-size: 1rem; //16px;
    font-weight: 500;
    color: $gray-900;
    margin-bottom: 10px;
  }
  .message {
    color: $gray-700;
    font-size: 12px;
  }
}
.comment-section {
  ul  {
    li {
			.badge {
				margin-bottom: 5px;
			}
      &:hover {
        .hover-action {
          opacity: 1;
        }
      }
    }
  }
}
// Weather widget
.weather-top {
  position: relative;
  .weather-over {
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    padding: 0.625rem 1.875rem; //10px 30px;
  }
  .weather-head, .weather-temp {
    color: $white;
  }
  .weather-temp {
    h2 {
      font-size: 3.75rem;
      font-weight: 600;
      margin: 0;
    }
  }
  .weather-head {
    h4 {
      font-size: 1.5rem; //24px;
      font-weight: 500;
    }
    i {
      font-size: 5rem;
    }
  }
}
.weather-bottom {
  ul {
    li {
      @include border(1px solid, $border-color, bottom);
      padding: 0.625rem 0.9375rem; //10px 15px;
      .w-icon {
        i {
          font-size: 1.5rem; //24px;
        }
      }
    }
  }
}
 // Tabed Notification Widget
.notification-box {
  border: none;
  ul {
    li {
      margin-bottom: 1.25rem; //20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.message-head {
  color: $gray-600;
  i {
    font-size: 1rem;
    margin-right: 5px;
  }
}
.notify-user {
  position: relative;
  .badge {
    width: 25px;
    border-radius: 100%;
    padding: 0 !important;
    margin: 0;
    font-size: 1rem;
    height: 25px;
    line-height: 20px;
    border: 2px solid $white;
    text-align: center;
    position: absolute;
    right: 0px;
    top: 30px;
  }
}
// New Customer
.small-btn {
  width: 33px !important;
  height: 30px !important;
  font-size: 1rem !important;
}
.new-customer-list {
  li {
    a {
      font-size: 1.1rem;
    	height: 30px;
    	width: 30px;
      line-height: 30px;
    	text-align: center;
      border-radius: 100%;
      padding: 0;
      margin: 3px;
      &:hover, &:active, &:focus {
        color: $white;
      }
    }
  }
}

// Recent Activity
.timeline-wrapper {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 9px;
    border: 1px solid $gray-300;
    top: 0px;
    bottom: 0px;
  }
  ul {
    li {
      margin-bottom: 1.25rem; //20px;
      &:last-child {
        margin-bottom: 0;
      }
      p {
        font-size: 14px;
      }
    }
  }
}
.timeline-ring {
	width: 20px;
	height: 20px;
	border-radius: 100%;
	border: 2px solid transparent;
	margin-right: 15px;
	align-self: start;
	margin-top: 2px;
	display: inline-block;
  z-index: 9;
  background-color: $block-bg;
}

//support request
.support-widget-wrap{
  ul{
    li{
      .content-title{
        width:20%;
      }
    }
  }
}

//stock exchange
.stock-exchange{
  ul{
    li{
      display:flex;
      justify-content: space-between;
      @include border (1px solid, $input-border-color, bottom);
      padding: 0.9375rem;
    }
  }
}

//share friend
.share-friend{
  .social-share{
    li{
      padding:0;
      margin-right:1rem;
      &:last-child{
        margin:0;
      }
      a{
          color:$white;
          height:30px;
          width:30px;
          display:flex;
          justify-content: center;
          align-items:center;
          border-radius:0.25rem;
      }
    }
  }
}

//Quote Of the Day
.review-slider {
  .rct-block-title {
    background-color: rgba(0,0,0,0.3);
    border-color: rgba(0,0,0,0.2);
    h4 {
      color: $white;
    }
  }
  .slick-dots{
    text-align:left;
    padding-left:1rem;
    bottom:0;
    li{
      margin:0;
      button{
        &:before{
          color:$white;
          font-size:0.5rem;
        }
      }
    }
  }
}


//Blog Layout Three

.blog-layout-three {
  overflow:hidden;
  .blog-overlay{
    top:0;
    bottom:0;
    left:0;
    right:0;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.81) 100%);
  }
}

//User Profile

.user-profile-widget{
  > img {
    height: 150px;
  }
  .user-avatar{
    margin-top:-6rem;
  }
}
/*========= Responsive ==========*/
@media (max-width: 1300px) and (min-width: 320px) {
  .promo-coupon img {
    width: 130px;
    height: 130px;
  }
}