/*======== Header Scss ========*/
.app-header {
  .rct-header {
    background-color: $navbar-bg;
    z-index: -9 !important;
  }
}
header {
  .dropdown-group-link {
    @include border (1px solid, $primary, top bottom left right);
    border-radius: $border-radius;
    font-size: 0.875rem; //14px;
    text-transform: capitalize;
    color: $navbar-link-color;
    background-color: $primary;
    box-shadow: 0 1px 5px 0 rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.12);
    ~ .dropdown-menu {
        width: 13rem;
    }
    i {
      margin-right: 5px;
      vertical-align: middle;
    }
    .dropdown-toggle::after {
      margin-left: 0.625rem;
      vertical-align: middle;
    }
  }
}
.header-icon {
  height: $navbar-icon-height;
  width: $navbar-icon-width;
  @include border(1px solid, $input-border-color, top bottom left right);
  border-radius: $border-radius;
  line-height: $navbar-icon-height;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  font-size: 1.25rem; //20px;
  transition: all 0.5s ease 0s;
  color: $gray-600;
  &:hover, &:focus, &.active {
    border-color: $primary !important;
    color: $primary !important;
    a {
      color: $primary;
    }
  }
}
.humburger {
  color: $hamburger-icon-color !important;
  border: 1px solid $input-border-color !important;
}
.navbar-right {
  margin: 0;
  >li:not(.dropdown), >li:not(.upgrade-btn.button) {
    button {
      color: $gray-600;
      font-size: 1.375rem; //22px;
      &:hover,
      &:focus,
      &.active {
        color: $primary;
      }
    }
  }
}
.notification-icon {
  padding: 0;
  margin: 0;
  .badge-bottom {
    position: absolute;
    bottom: 0.7rem;
    right: 0;
    left: 3rem;
  }
  &:hover {
    a {
      button {
        color: $blue;
      }
    }
  }
  a {
    button {
      color: $gray-600;
      font-size: 1.375rem; //22px;
    }
  }
  &.show .dropdown-menu {
    opacity: 1;
    transform: translate3d(0, 50px, 0)!important;
    visibility: visible;
  }
  .dropdown-menu {
    @extend .dropdown-menu;
    width: 350px;
    right: -22px!important;
    .dropdown-head {
      padding: 0.625rem 0.9375rem; //10px 15px;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      @include border(1px solid, $input-border-color, bottom);
      span {
        font-weight: 600;
        color: $gray-900;
        font-size: 0.875rem;
      }
    }
    .dropdown-foot {
      padding: 0.625rem 0.9375rem; //10px 15px;
      font-weight: 600;
      box-shadow: 0px -2px 2px rgba(0,0,0,0.1);
    }
    .dropdown-body {
      li {
        position: relative;
        padding: 0.625rem; //10px;
        @include border(1px solid, $input-border-color, bottom);
        button {
          min-height: 25px !important;
          padding: 2px 10px !important;
          font-size: 12px;
          min-width: 70px !important;
          i {
            margin-right: 0.5rem;
          }
        }
      }
      .media {
        .media-body {
          h5 {
            font-size: 14px;
          }
        }
      }
    }
  }
}
.hover-close {
  opacity: 0;
}
.cart-icon {
  @extend .notification-icon;
  li{
    &:hover {
      .hover-close {
        opacity: 1;
      }
    }
  }
}
@media (max-width: 1199px) and (min-width: 320px) {
  .rct-header {
    &.mui-fixed {
      left: 0;
    }
  }
  .rct-app-content {
    width: 100% !important;
  }
}
/*========= Dashboard Overlay ======*/
@-webkit-keyframes show {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
@keyframes show {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
.show {
  -webkit-animation-name: show;
  animation-name: show;
}
.dashboard-overlay {
	width: 100%;
	background-color: rgba(0,0,0,0.9);
	position: absolute;
	left: 0;
	right: 0;
	padding: 1.875rem; //30px;
	height: 100vh;
  top: 63px;
  z-index: 99;
  overflow-y: auto;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  .dashboard-overlay-content {
    color: $gray-900;
    .arrow-icon {
      font-size: 2.25rem; //36px;
      color: $success;
    }
    .totle-status {
      h2 {
        font-size: 1.5rem; //24px;
      }
    }
  }
  .table {
    tr {
      td {
        &:last-child {
          text-align: right;
        }
      }
    }
  }
  .progress {
    height: 6px;
  }
  .rating-star {
    li {
      font-size: 1.875rem; //30px;
    }
  }
  .report-title {
  	background: $gray-100;
  	font-size: 0.875rem; //14px;
  	padding: 0.9375rem; //15px;
    margin-bottom: 0;
  }
  .overlay-head {
    padding: 0 0.9375rem; //15px;
    .closed {
      font-size: 1.375rem; //22px;
      color: $white;
      font-weight: 700;
    }
  }
}
/*======== Search Form =======*/
.search-icon {
  width: 30rem;
  position: relative;
  &::before {
    content: "\f1c3";
    font-family: 'Material-Design-Iconic-Font';
    color: $input-placeholder-color;
    position: absolute;
    left: 0;
    top: -2px;
    font-size: 1.625rem; //26px;
  }
  .Select-arrow-zone {
    display: none;
  }
  .Select-menu-outer {
    z-index: 999;
  }
  .Select-control{
    overflow-x:hidden; 
  }
  .Select-placeholder, .Select-input, .Select-value {
    padding-left: 1.875rem !important; //30px;
  }
  .search-form-wrap{
    transform:translateY(-100%);
    transition:all 0.5s ease-in-out;
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color:rgba(0,0,0,0.9);
    z-index:999;
    opacity:0;
    .close-btn{
      position:absolute;
      right:2.5rem;
      top:4rem;
    }
    form{
      width:75%;
      .form-group{
        position: relative;
        .search-input{
          border:none;
          border-bottom: 1px solid $white;
          background-color:transparent !important;
          box-shadow:none !important;
          padding:1rem 3rem 1rem 0;
          color:$white;
        }
        .search-btn{
          position:absolute;
          right:0;
          top:0;
          bottom:0;
        }
      }
    }
    &.search-slide{
      transform:translateY(0);
      opacity:1;
    }
  }
}

/*======= Responsive ========*/

@media (min-width: 651px) {
  .search-form-wrap,.search-icon-btn{
    display:none !important;
  }
  .search-form{
    display: block !important;
  }
}
@media (max-width: 650px) {
  .search-form-wrap,.search-icon-btn{
    display:block !important;
  }
  .search-form,.search-icon:before{
    display: none !important;
  }
}
@media (max-width: 991px) {
  .app-main-container {
    > div {
      > div {
        box-shadow: none !important;
      }
    }
  }
  .notification-icon, .cart-icon {
    .dropdown-menu {
      .text-muted.fs-12 {
      	display: none;
      }
    }
  }
}
@media (max-width:450px) and (min-width:320px) {
  .notification-icon, .cart-icon, .rct-dropdown {
    .dropdown-menu {
      &::after, &::before {
        border: none !important;
      }
    }
  }
  .notification-icon, .cart-icon {
    .dropdown-menu {
      width: 280px;
      right: 0px !important;
    }
  }
}
@media screen and (max-width:359px) {
  .notification-icon, .cart-icon {
    .dropdown-menu {
      width: 240px;
    }
  }
}
