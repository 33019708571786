/*====== All Custom Variable Interduce Here ======*/
$light-yellow: #fff59d;
$twitter: #52aeff;
$facebook: #405de6;
$google: #f13a30;
$pinterest: #d32f2f;
$instagram: #8d6e63;
$rss: #ff9800;
$tumblr: #607d8b;
$linkedin: #0876de;
$dribbble: #f06292;
$youtube: #d50000;
$github: #424242;
$skype: #4fc3f7;

$colors: () !default;
$colors: map-merge(
  (
    "light-yellow": $light-yellow,
    "twitter": $twitter,
    "facebook": $facebook,
    "google": $google,
    "pinterest": $pinterest,
    "instagram": $instagram,
    "rss": $rss,
    "tumblr": $tumblr,
    "linkedin": $linkedin,
    "dribbble": $dribbble,
    "youtube": $youtube,
    "github": $github,
    "skype": $skype,
  ),
  $colors
);

$light-yellow: $light-yellow;
$twitter: $twitter;
$facebook: $facebook;
$google: $google;
$pinterest: $pinterest;
$instagram: $instagram;
$rss: $rss;
$tumblr: $tumblr;
$linkedin: $linkedin;
$dribbble: $dribbble;
$youtube: $youtube;
$github: $github;
$skype: $skype;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "light-yellow": $light-yellow,
    "twitter": $twitter,
    "facebook": $facebook,
    "google": $google,
    "pinterest": $pinterest,
    "instagram": $instagram,
    "rss": $rss,
    "tumblr": $tumblr,
    "linkedin": $linkedin,
    "dribbble": $dribbble,
    "youtube": $youtube,
    "github": $github,
    "skype": $skype,
  ),
  $theme-colors
);

// html font
$html-font-size: 0.8rem;

// Block Settings
$block-bg: $white;
$block-border-radius: 0.3125rem;
$block-border: none;
$block-spacing: 1.25rem 1.25rem; //20px 20px;
$block-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.12), 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.24);
$block-hover-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.12),
  0 8px 8px 0 rgba(0, 0, 0, 0.24);
$block-margin-bottom: $grid-gutter-width; //24px
$block-transition: all 0.4s ease-in-out 0s;

$block-title-color: $gray-900;
$block-title-spacing: 0.9375rem 1.25rem; //15px 20px;
$block-title-font-size: 1.125rem; //18px
$block-title-font-weight: 500;
$block-title-transform: capitalize;
$block-title-border-color: $blue;
$block-title-border-width: 0.25rem;

$block-contextual-color: $gray-800;
$block-contextual-font-size: 1rem;

$block-footer-border-width: 0.0625rem;
$block-footer-border-color: $gray-300;

//Breadcrumb Variable
$breadcrumb-font-size: 1.875rem; //30px;
$breadcrumb-font-color: $gray-900;

// Dark theme
$dark-bg: #424242;
$dark-bg-color: #fff;
$dark-bg-container: #303030;
$dark-border-color: rgba(0, 0, 0, 0.1);
$dark-placeholder: #303030;

// Input Fields
$input-lg-height: 3.25rem;
$input-radius: 2.5rem;

// Gradient Theme Color
$overlay-dark: rgba(0, 0, 0, 0.8);
$light-primary: darken($primary, 15%); // Primary
$light-blue: darken($secondary, 15%); // blue
$light-info: darken($info, 15%); // Info
$light-success: darken($success, 15%); // Success
$light-warning: darken($warning, 15%); // warning
$light-danger: darken($red, 15%); // danger
$light-purple: darken($purple, 15%); // purple

// Navbar Varibale
$navbar-height: 4rem; //80px;
$navbar-bg: $white;
$hamburger-icon-color: $gray-600;
$navbar-icon-height: 2.1875rem;
$navbar-icon-width: 2.1875rem;
$navbar-link-color: $white;

// Footer Variable
$footer-height: 3.125rem;
$footer-bg: $navbar-bg;
$footer-box-shadow: none;

// Square Box
$square-font-size: 0.5; // 50% relative to the container
$square-box: (10, 15, 20, 30, 40, 50, 60, 70, 80, 100, 120);

// Sidebar Variable
$sidebar-width: calc(100% - 240px) !default; //280px;
$sidebar-overlay: $light-primary;
$sidebar-box-shadow: none;

$sidebar-link-fontsize: 1rem; //14px
$sidebar-link-font-weight: 400;
$sidebar-link-font-color: $white;
$sidebar-link-transform: capitalize;
$sidebar-link-spacing: 0.9375rem 2rem; //15px 40px;

$sidebar-icon-font-size: 1rem; //14px;

$sidebar-hover-bg: rgba(0, 0, 0, 0.22);
$sidebar-hover-color: $white;

$sidebar-submenu-font-size: 1rem; //14px
$sidebar-submenu-font-color: $white;
$sidebar-submenu-spacing: 0.9375rem 2.5rem 0.9375rem 4.375rem;

// Mini Sidebar Variable
$mini-sidebar-width: 85px;

// Horizontal Menu sidebar Variable
$hoz-menu-bg: #001429;
$hoz-nav-link-color: $white;
$hoz-nav-font-size: 0.875rem; //14px
$hoz-nav-link-spacing: 0.9375rem 1.563rem 0.9375rem 1.563rem; //15px 30px;
$hoz-nav-link-hover-bg: #3e4155;
$hoz-menu-height: 4.375rem; //70px;
$hoz-sub-menu-width: 15rem;

// Social Link Variable
$social-icon-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.19);

// boxed layout
$boxed-bg: url("../../assets/img/boxed-bg.png");
$dark-layout-bg: url("../../assets/img/dark-layout-bg.png");

// Weather Widget Variables
// $weather-bg: url("../flag-icons/AD.png");
