@font-face {
  font-family: "Aeonik-Regular";
  src: url(https://cdn.gravito.net/fonts/Aeonik-Regular.woff2) format("woff");
}
@font-face {
  font-family: "Aeonik-Medium";
  src: local("Aeonik-Medium"),
    url(https://cdn.gravito.net/fonts/Aeonik-Medium.woff2) format("woff");
}

body {
  letter-spacing: 1px;
}

.text-aling-center {
  text-align: center !important ;
}
.height-150 {
  height: 150px !important;
}
.height-100 {
  height: 100px !important;
}
